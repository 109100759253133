import DELFooterBenefitsSliderController from '@/delonghi/Controllers/DELFooterBenefitsSlider/DELFooterBenefitsSliderInitiator';
import DELFooterNewsletterController from '@/delonghi/Controllers/DELFooterNewsletterController';
import DELHeaderCollectionsSliderController from '@/delonghi/Controllers/DELHeaderCollectionsSlider/DELHeaderCollectionsSliderInitiator';
import DELHeaderLinksStripeController from '@/delonghi/Controllers/DELHeaderLinksStripe/DELHeaderLinksStripeInitiator';
import DELHeaderNavigationController from '@/delonghi/Controllers/DELHeaderNavigation/DELHeaderNavigationInitiator';
import DELHeaderProductsSliderController from '@/delonghi/Controllers/DELHeaderProductsSlider/DELHeaderProductsSliderInitiator';
import DELWishlistController from '@/delonghi/Controllers/DELWishlistController';
import '@/style/nutribullet/Main.scss';
import HotSpotComponentInitiator from '@/utils/Controllers/HotSpot/HotSpotComponentInitiator';

// components controller
import DELBeveragesDisplaySliderController from '@/delonghi/Controllers/DELBeveragesDisplaySlider/DELBeveragesDisplaySliderInitiator';
import DELBeveragesSliderController from '@/delonghi/Controllers/DELBeveragesSlider/DELBeveragesSliderInitiator';
import DELCategoryMoodboardController from '@/delonghi/Controllers/DELCategoryMoodboard/DELCategoryMoodboardInitiator';
import DELContentCardsController from '@/delonghi/Controllers/DELContentCards/DELContentCardsInitiator';
import DELContentSliderController from '@/delonghi/Controllers/DELContentSlider/DELContentSliderInitiator';
import DELFeaturesListController from '@/delonghi/Controllers/DELFeaturesList/DELFeaturesListInitiator';
import DELGalleryComponentController from '@/delonghi/Controllers/DELGalleryComponentController';
import DELHeaderController from '@/delonghi/Controllers/DELHeaderController';
import DELKeyFeaturesController from '@/delonghi/Controllers/DELKeyFeatures/DELKeyFeaturesInitiator';
import initMultiAccordionController from '@/delonghi/Controllers/DELMultiAccordion/DELMultiAccordionControllerInitiator';
import DELProductMoodBoardController from '@/delonghi/Controllers/DELProductMoodBoard/DELProductMoodBoardInitiator';
import DELPromoSliderController from '@/delonghi/Controllers/DELPromoSlider/DELPromoSliderInitiator';
import DELShopTheLookController from '@/delonghi/Controllers/DELShopTheLook/DELShopTheLookInitiator';
import initDELStoriesController from '@/delonghi/Controllers/DELStories/DELStoriesInitiator';
import DELTextGroupController from '@/delonghi/Controllers/DELTextGroup/DELTextGroupInitiator';
import DELVideoFullController from '@/delonghi/Controllers/DELVideoFull/DELVideoFullInitiator';
import DELVideoSliderController from '@/delonghi/Controllers/DELVideoSlider/DELVideoSliderInitiator';

window.addEventListener('load', () => {
  DELHeaderLinksStripeController();
  DELHeaderNavigationController();
  DELHeaderProductsSliderController();
  DELHeaderCollectionsSliderController();
  DELFooterBenefitsSliderController();
  DELFooterNewsletterController.init();
  DELWishlistController();
  DELTextGroupController();
  DELCategoryMoodboardController();
  DELPromoSliderController();
  DELProductMoodBoardController();
  DELContentSliderController();
  DELShopTheLookController();
  DELKeyFeaturesController();
  DELGalleryComponentController();
  DELVideoFullController();
  DELContentCardsController();
  initDELStoriesController();
  DELFeaturesListController();
  DELVideoSliderController();
  initMultiAccordionController();
  DELBeveragesSliderController();
  DELBeveragesDisplaySliderController();
  HotSpotComponentInitiator();
  DELHeaderController();
});
